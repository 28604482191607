import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '../store'
import King from '../views/King.vue';
import Kong from '../views/Kong.vue';
import Birthday from '../views/Birthday.vue';
import BirthdayExt from '../views/BirthdayExt.vue';
import NotFound from '../views/NotFound.vue';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'index',
    component: King,
    props: true,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/kong',
    name: 'kong',
    component: Kong,
    props: true,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/bday',
    name: 'bday',
    component: Birthday,
    props: true,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/bdayext',
    name: 'bdayext',
    component: BirthdayExt,
    props: true,
    meta: {
      requiresAuth: true
    },
  },
  { path: '*', component: NotFound }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  next()
});


export default router;
