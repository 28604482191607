








import { Component, Prop, Vue } from 'vue-property-decorator'
import VueRouter from 'vue-router';

@Component({ components: { } })
export default class Kong extends Vue {

  howmuch =0
  touchStart  = 0

  created() {
    document.addEventListener('wheel', this.handleScroll);
    document.addEventListener('touchmove', this.handleTouchmove);
    document.addEventListener('touchstart', this.handleTouchstart);
  }

  beforeDestroy () {
    document.removeEventListener('wheel', this.handleScroll);
    document.removeEventListener('touchmove', this.handleTouchmove);
    document.removeEventListener('touchstart', this.handleTouchstart);
  }

  handleTouchstart(e: TouchEvent){
    this.touchStart = e.touches[0].clientY
  }

  handleTouchmove(e: TouchEvent){
    let distance = e.touches[0].clientY - this.touchStart

    let w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    let element = document.getElementById("noe");

    let newState = nextValue(this.howmuch, distance, w!, element!.offsetWidth);
    this.howmuch = newState;

    element!.style.letterSpacing = newState + "px";
    element!.style.marginRight = -newState + "px";

  }

  handleScroll(e: WheelEvent){
    let w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    let element = document.getElementById("noe");

    let newState = nextValue(this.howmuch, e.deltaY, w!, element!.offsetWidth);
    this.howmuch = newState;

    element!.style.letterSpacing = newState + "px";
    element!.style.marginRight = -newState + "px";
  }

};

const nextValue = (lastValue: number, deltaY: number, viewWidth: number, elementWidth: number) => {
  if(deltaY > 0 && elementWidth + 150 > viewWidth){
    return lastValue;
  }

  let calculated = lastValue + (deltaY / 100);
  return calculated > 0 ? calculated : 0;
};

