








import { Component, Prop, Vue } from 'vue-property-decorator'
import VueRouter from 'vue-router';

@Component({ components: { } })
export default class NotFound extends Vue {


};

