

















import {Component, Vue} from 'vue-property-decorator'
import {hello} from "@/api";

export interface Person  {
  name: string,
  birthday: Date,
  daysLeft: number
}

@Component({ components: { } })
export default class BirthdayExt extends Vue {


  data = [
      {name:"Julaften", bday:"0000-12-24"},
      {name:"Mimmi", bday:"1944-03-21"},
      {name:"Besse", bday:"1951-06-15"},
      {name:"Mons", bday:"2013-06-15"},
      {name:"Anton", bday:"2018-01-24"},
      {name:"Hilde", bday:"1976-10-21"},
      {name:"Arnulf", bday:"1978-04-06"}
  ]

  msPerDay = 1000 * 60 * 60 * 24;


  todayDate = new Date()

  today = Date.UTC(this.todayDate.getFullYear(), this.todayDate.getMonth(), this.todayDate.getDate())

  created() {
    hello();
  }

  get ordered(){

    let withDaysLeft = this.data.map(value => {
      let daysLeft = this.daysLeft(value);
      let modal:Person = {name:value.name, birthday: new Date(value.bday), daysLeft: daysLeft};
      return modal;
    });

    withDaysLeft.sort((a,b) => {
      if(a.daysLeft === 0){
        return -1;
      }

      if(b.daysLeft === 0){
        return 1;
      }

      return a.daysLeft - b.daysLeft;
    })

    return withDaysLeft;

  }

  daysLeft(person:any){
    let dd = new Date(person.bday)

    let thisYear = Date.UTC(this.todayDate.getFullYear(), dd.getMonth(), dd.getDate())
    let nextYear = Date.UTC(this.todayDate.getFullYear() +1, dd.getMonth(), dd.getDate())


    if(this.today == thisYear){
      return 0
    } else if(this.today < thisYear){


      return  Math.floor((thisYear - this.today) / this.msPerDay);
    } else {
      return  Math.floor((nextYear - this.today) / this.msPerDay);
    }
  }

};


